nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;

  z-index: 100;

  background-color: white;

  box-shadow: 2px 0 20px 20px rgba(0, 0, 0, 0.2);

  .logo > img {
    height: 50px;
  }
}

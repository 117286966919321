button {
  border-radius: 25px;
  padding: 5px 15px;
  border: unset;

  background-color: $blue;
  color: white;
  opacity: 0.9;

  transition-duration: 0.3ms;

  white-space: nowrap;

  box-shadow: 0px 0px 20px 1px rgba(255, 255, 255, 0.4);

  img {
    height: 35px;
    filter: invert(1);
  }

  &:hover {
    opacity: 1;
  }

  &.secondary {
    background-color: $green;
  }
}

.cookies-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  padding: 15px;
  width: 100vw;
  bottom: 0;
  left: 0;

  background-color: white;
  border-top: 2px solid $blue;
}

#hero {
  background-color: $bluebg;
  color: white;
  position: relative;

  .bg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    background-position: center center;
    background-size: cover;
  }

  .oval {
    height: 100px;
    background-color: white;
    width: 100%;
    border-radius: 100%;

    transform: translateY(50%);
  }

  .keypoints {
    border: 2px solid white;
    border-radius: 25px;
    padding: 15px 0;

    img {
      height: 25px;
      filter: invert(1);
    }
  }
}

#strength {
  .points {
    .img-container {
      background-color: $bluebg;
      padding: 15px;
      border-radius: 100%;
      opacity: 0.5;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        border-radius: 100%;
        border: 1px solid $bluebg;
        top: -5px;
        left: -5px;
        right: -5px;
        bottom: -5px;
      }

      img {
        height: 40px;
        filter: invert(1);
      }
    }
  }
}

#services {
  color: white;
  background-color: $greenbg;
  position: relative;
  overflow: hidden;

  .bg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    background-position: center center;
    background-size: cover;
  }

  p {
    font-weight: normal;
  }

  .line {
    width: 50px;
    height: 5px;
    background-color: $bluebg;
  }

  .oval {
    height: 100px;
    background-color: white;
    width: 100%;
    border-radius: 100%;

    transform: translateY(50%);
    &:first-child {
      transform: translateY(-50%);
    }
  }
}

#pre {
  &.dark {
    background-color: $bluebglight;
  }

  .keypoints {
    border: 2px solid $blue;
    border-radius: 25px;
    padding: 25px 0;

    img {
      height: 25px;
    }
  }
}

#reviews {
  background-color: $bluebglight;
  .chat-icon {
    background-color: $bluebg;
    padding: 15px;
    border-radius: 100%;
    opacity: 0.5;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      border-radius: 100%;
      border: 1px solid $bluebg;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
    }

    img {
      height: 40px;
      filter: invert(1);
    }
  }
}

#where {
  background-color: $bluebglight;
}

@import url('https://fonts.googleapis.com/css2?family=Volkhov:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@import 'colors';
@import 'button';
@import 'nav';
@import 'footer';
@import 'home';
@import 'cookiesbanner';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  padding-top: 75px;
  font-family: 'Volkhov', serif;
}

#root,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

p {
  font-family: 'Open Sans', sans-serif;
  font-weight: light;
  font-size: large;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.border {
  border: 1px solid black;
}

.nowrap {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.img-canvas {
  // padding: 5px;
  background-color: whitesmoke;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
    background-color: $bluebg;
    z-index: -1;

    box-shadow: 0 0 30px 20px rgba(0, 0, 0, 0.2);
  }
}

a {
  white-space: nowrap;
  text-decoration: none;
  color: unset;
}
